import { supportsPassiveEvents } from 'detect-it';
import { trapModalFocus, releaseModalFocus } from '../lib/trapModalFocus';

import { gsap } from 'gsap';

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

var unloads = [];
var trapModalFocusListener = null;
var openingElement = null;

function unload () {
	/*
	* Multiple listeners has been created. Remove them when site closes.
	*/
	unloads.forEach((item, i) => {
		console.log('unloading:', item.event);
		item.elem.removeEventListener(item.event, item.func);
	});
	if (trapModalFocusListener) {
		// removes the event-listener and allows tabbing outside specified modal.
		releaseModalFocus(trapModalFocusListener);
		trapModalFocusListener = null;
	}
}

function initialize () {
	toggleMenu();
	window.addEventListener('beforeunload', unload);
}

var menuCTAButton = document.querySelector('.menu-join-button');
var logo = document.querySelector('.logo-holder svg');
var logoHolder = document.querySelector('.logo-container');
var menuToggle = document.querySelector('.burger');
var menu = document.querySelector('nav.menu');
var mainMenu = menu.querySelector('.main-menu');
var listItems = menu.querySelectorAll('.main-menu li');
var footer = document.querySelector('footer');
var header = document.querySelector('.main-header');

var listDesignElement = menu.querySelector('.list-design-element');

function toggleMenu () {
	menuToggle.addEventListener('click', listener);
	unloads.push({elem: menuToggle, event: 'click', func: listener});
}

function listener (evt) {
	menuToggle.classList.toggle('active');
	logoHolder.classList.toggle('menu-open');
	footer.classList.toggle('menu-open');
	document.body.classList.toggle('noscroll');

	if (menuToggle.getAttribute('aria-expanded') === 'true') {
		// close menu!
		console.log('Close menu!');
		menuToggle.setAttribute('aria-expanded', 'false');
		menu.setAttribute('aria-hidden', 'true');

		let tl = gsap.timeline({defaults: {ease: "power3.out"}});
		tl.to(listDesignElement, {width: 0, x: 0});

		let arrayNodes = Array.from(listItems).reverse();

		tl.to(arrayNodes, {opacity: 0, y: 50, duration: 0.4, stagger: 0.1}, '>-0.3');

		// let i = 0;
		// for (var item of arrayNodes.reverse()) {
		// 	tl.to(item, {opacity: 0, y: 50, duration: 0.4}, '>-0.35');
		// 	i++;
		// }
		tl.to(menu, {x: -window.innerWidth, duration: 0.3});
		tl.set(menu, {visibility: 'hidden'});

		if (trapModalFocusListener) {
			// removes the event-listener and allows tabbing outside specified modal. And set focus to initiating element
			releaseModalFocus(trapModalFocusListener, menuToggle);
			trapModalFocusListener = null;
		}
	} else {
		// open menu
		console.log('Open menu!');

		menuToggle.setAttribute('aria-expanded', 'true');
		menu.setAttribute('aria-hidden', 'false');

		let tl = gsap.timeline({defaults: {ease: "power3.out"}});

		let activeElem = mainMenu.querySelector('li .active');
		if (activeElem) {
			const distFromTop = activeElem.closest('li').offsetTop;
			const menuFromTop = mainMenu.offsetTop;
			var style = window.getComputedStyle(activeElem);

			tl.set(listDesignElement, {backgroundColor: style.getPropertyValue('color'), y: distFromTop + menuFromTop});
		}

		tl.set(menu, {visibility: 'visible'});
		tl.to(menu, {x: 0, duration: 0.3});

		tl.fromTo(listItems, {opacity: 0, y: 30}, {opacity: 1, y: 0, duration: 0.6, stagger: 0.1});

		// let i = 0;
		// for (var item of listItems) {
		// 	tl.fromTo(item, {opacity: 0, y: 30}, {opacity: 1, y: 0, duration: 0.6}, i > 0 ? '>-0.5' : null);
		// 	i++;
		// }
		if (activeElem) {
			tl.to(listDesignElement, {width: window.innerWidth >= 997 ? 76 : 23, x: 10});
		}

		// opening modal. Trap the focus inside.
		trapModalFocusListener = trapModalFocus(header);
	}
}
