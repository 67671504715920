import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/respimg/ls.respimg";
import "./components/header.js";
import "focus-visible";
import { throttle } from "lodash";
import { gsap } from "gsap";
import { formatTime } from "./utils/formatTime";

import AOS from "aos";

import "../css/app.pcss";

var unloads = [];

AOS.init({
  offset: 150,
  duration: 400,
  easing: "ease-out",
});

if (!("object-fit" in document.createElement("a").style)) {
  require("lazysizes/plugins/object-fit/ls.object-fit");
}

Element.prototype.hasClass = function (className) {
  return (
    this.className &&
    new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className)
  );
};

// Add class do parent for lazyloaded images
document.addEventListener("lazyloaded", function (event) {
  AOS.refresh();
});

// Check elements to create sizes for srcset
window.onload = window.lazySizes.autoSizer.checkElems;

function unload() {
  /*
   * Multiple listeners has been created. Remove them when site closes.
   */
  unloads.forEach((item, i) => {
    console.log("unloading:", item.event);
    item.elem.removeEventListener(item.event, item.func);
  });
}
window.addEventListener("beforeunload", unload);

document.addEventListener("DOMContentLoaded", async function () {
  // Header styles change on scroll listener
  const thro = throttle(callback, 50);
  window.addEventListener("scroll", thro);
  unloads.push({ elem: window, event: "scroll", func: thro });
  let headerHidden = false;
  const headerLimit = 100;
  const logo = document.body.querySelector(".logo-container");
  function callback(e) {
    if (window.scrollY > headerLimit && !headerHidden) {
      headerHidden = true;
      console.log("Hide header!");
      gsap.to(logo, {
        opacity: 0,
        pointerEvents: "none",
      });
      logo.setAttribute("tabindex", "-1");
    } else if (window.scrollY <= headerLimit && headerHidden) {
      headerHidden = false;
      console.log("Show header!");
      gsap.to(logo, {
        opacity: 1,
        pointerEvents: "auto",
      });
      logo.setAttribute("tabindex", "0");
    }
  }

  // Set embed aspect ratios
  let videosArray = document.querySelectorAll(
    '.text-module iframe[src^="https://player.vimeo.com"], .text-module iframe[src^="//player.vimeo.com"], .text-module iframe[src^="https://www.youtube.com/embed/"], .text-module iframe[src^="//www.youtube.com/embed/"]'
  );
  videosArray = Array.prototype.slice.call(videosArray);
  videosArray.map((elem) => {
    let videoHeight =
      elem.height !== "" ? elem.height : elem.style.height.replace("px", "");
    let videoWidth =
      elem.width !== "" ? elem.width : elem.style.width.replace("px", "");

    elem.dataset.aspectRatio = videoHeight / videoWidth;
    elem.removeAttribute("height");
    elem.removeAttribute("width");
    elem.removeAttribute("style");
  });

  // Resize videos on window resize
  function resizeVideo() {
    videosArray.map((elem) => {
      if (elem.parentNode) {
        let newWidth = elem.parentNode.offsetWidth;
        elem.width = newWidth;
        elem.height = newWidth * elem.dataset.aspectRatio;
      }
    });
  }

  window.addEventListener("resize", function (evt) {
    resizeVideo();
  });

  resizeVideo();

  // Module expand
  const expandButtons = document.querySelectorAll("[data-read-more]");
  expandButtons.forEach((button) => {
    button.addEventListener("click", function (e) {
      e.preventDefault();

      const parent = button.closest(".read-more__parent");

      const textAndImage = button.closest(".text-and-image-module");
      const desktop = window.matchMedia("(min-width: 992px)").matches;

      if (textAndImage && desktop) {
        if (textAndImage.dataset.fromTop) {
          // Already fixed
        } else {
          // We need to stick the image to the current position in the div.
          const image = textAndImage.querySelector(
            ".text-and-image-module__image-container"
          );

          const fromTop = image.offsetTop;

          textAndImage.dataset.fromTop = fromTop;
          // image.style.transition = "none";
        }
      }

      const content = parent.querySelector(".read-more__content");
      const teaserContent = parent.querySelector(".read-more__content-teaser");
      const textContainer = button.querySelector(".read-more__text") || button;
      const buttonIcon = button.querySelector(".icon");

      // const target = document.querySelector(button.dataset.readMore);
      content.classList.toggle("hidden");
      if (content.classList.contains("hidden")) {
        // Closed
        // Update button text.
        textContainer.innerHTML = button.dataset.readMore;

        // If image we need to stick the image back to the original position (0px translateY)
        if (textAndImage && desktop) {
          const image = textAndImage.querySelector(
            ".text-and-image-module__image-container"
          );
          image.firstElementChild.style.transform = "translateY(0px)";
        }

        // Scroll to top of the module
        const rect = parent.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const top = rect.top + scrollTop;
        window.scrollTo({
          top: top - 100,
          behavior: "smooth",
        });

        buttonIcon.classList.remove("rotate-180");
        teaserContent.classList.add("mask-fadeout-bottom-small");
      } else {
        // Expanded
        textContainer.innerHTML = button.dataset.readLess;

        // If image we need to stick the image to initial position in the div.
        if (textAndImage && desktop) {
          const image = textAndImage.querySelector(
            ".text-and-image-module__image-container"
          );
          image.firstElementChild.style.transform =
            "translateY(-" +
            (image.offsetTop - textAndImage.dataset.fromTop) +
            "px)";
        }
        buttonIcon.classList.add("rotate-180");
        teaserContent.classList.remove("mask-fadeout-bottom-small");
      }
    });
  });

  /**
   * Audio players
   */
  const audioPlayers = document.querySelectorAll(".audio-player");
  audioPlayers.forEach((player) => {
    const container = player.closest(".audio-player__container");
    const playButton = container.querySelector(".audio-player__control-play");
    const playIcon = playButton.querySelector(
      ".audio-player__control-play-icon"
    );
    const pauseIcon = playButton.querySelector(
      ".audio-player__control-pause-icon"
    );
    const progressBar = container.querySelector(".audio-player__progress-bar");
    const totalTime = container.querySelector(".audio-player__total-time");
    const currentTime = container.querySelector(".audio-player__current-time");
    const text = container.querySelector(".audio-player__text");
    const infoContainer = container.querySelector(".audio-player__info");
    const forward = container.querySelector(".audio-player__control-forward");
    const rewind = container.querySelector(".audio-player__control-backward");

    let duration = 0;

    // Get audio duration
    if (player.readyState > 0) {
      duration = player.duration;
      totalTime.innerHTML = formatTime(duration);
    } else {
      player.addEventListener("loadedmetadata", function () {
        duration = player.duration;
        totalTime.innerHTML = formatTime(duration);
      });
    }

    player.addEventListener("timeupdate", function () {
      const currentTimeValue = player.currentTime;
      currentTime.innerHTML = formatTime(currentTimeValue);

      if (progressBar) {
        const progress = currentTimeValue / duration;
        progressBar.value = progress;
      }

      if (rewind) {
        rewind.disabled = currentTimeValue === 0 ? true : false;
        if (currentTimeValue === 0) {
          rewind.classList.add("opacity-50");
        } else {
          rewind.classList.remove("opacity-50");
        }
      }

      if (forward) {
        forward.disabled = currentTimeValue === duration ? true : false;
        if (currentTimeValue === duration) {
          forward.classList.add("opacity-50");
        } else {
          forward.classList.remove("opacity-50");
        }
      }
    });

    player.addEventListener("ended", function () {
      playIcon.classList.remove("hidden");
      pauseIcon.classList.add("hidden");
    });

    playButton.addEventListener("click", function (e) {
      e.preventDefault();

      if (player.paused) {
        player.play();
        playIcon.classList.add("hidden");
        pauseIcon.classList.remove("hidden");

        if (infoContainer.classList.contains("hidden")) {
          const tl = gsap.timeline({
            onComplete: () => {
              infoContainer.classList.remove("hidden");
            },
          });
          tl.to(text, {
            opacity: 0,
            display: "none",
            duration: 0.3,
          });
          tl.set(infoContainer, {
            opacity: 0,
            display: "flex",
          });
          tl.to(infoContainer, {
            opacity: 1,
          });
        }
      } else {
        player.pause();
        playIcon.classList.remove("hidden");
        pauseIcon.classList.add("hidden");
      }
    });

    forward.addEventListener("click", function (e) {
      e.preventDefault();
      if (player.currentTime < duration - 30) {
        player.currentTime += 30;
      } else {
        player.currentTime = duration;
      }
    });

    rewind.addEventListener("click", function (e) {
      e.preventDefault();
      if (player.currentTime > 30) {
        player.currentTime -= 30;
      } else {
        player.currentTime = 0;
      }
    });
  });
});

if (module.hot) {
  module.hot.accept();
}
