export const formatTime = (timeInSeconds) => {
    var mins = Math.floor(timeInSeconds / 60);
    if (mins < 10) {
        mins = '0' + String(mins);
    }
    var secs = Math.floor(timeInSeconds % 60);
    if (secs < 10) {
        secs = '0' + String(secs);
    }

    return mins + ':' + secs;
};